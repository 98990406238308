import React from 'react';
import keygen from '~helpers/keygen';
// import LazyImage from 'gatsby-core-theme/src/hooks/lazy-image';
// import Icons1 from '../../../../../static/images/depositBonus.png';
// import Icons2 from '../../../../../static/images/freespins.png';
// import Icons3 from '../../../../../static/images/nodeposit.png';
import styles from './selling-points.module.scss';

const SellingPoints = ({ sellingPoints, icon, limit = 3 }) => {
  if (!sellingPoints || sellingPoints.length === 0) {
    return <></>;
  }

  return (
    <ul className={styles.sellingPoint || ''}>
      {sellingPoints.slice(0, limit).map((item, index) => (
        <li className={`${!icon && (styles.tick || '')}`} key={keygen()}>
          {/* <LazyImage
            src={index === 0 ? Icons1 : index === 1 ? Icons2 : Icons3}
            alt="Toplist Icons"
            width={30}
            height={30}
            className={styles.image}
          /> */}
          {item}
        </li>
      ))}
    </ul>
  );
};

export default SellingPoints;
